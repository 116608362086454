import React, { FC } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";

interface QuillProps {
  value: string;
  onChange: (content: string) => void;
}

const quillModules = {
  toolbar: [],
};

const quillFormats = ["bold", "italic", "list", "bullet"];

const StyledQuillContainer = styled.div`
  * {
    font-family: Arial, sans-serif !important;
  }

  .ql-container {
    font-size: 1em;
    line-height: 1.7;
    height: 100%;
  }

  .ql-editor {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    min-height: 200px;
    max-height: 800px;
    overflow-y: auto;
  }
`;

export const Quill: FC<QuillProps> = ({ value, onChange }) => {
  return (
    <StyledQuillContainer>
      <ReactQuill
        defaultValue={value}
        value={value}
        onChange={onChange}
        modules={quillModules}
        formats={quillFormats}
      />
    </StyledQuillContainer>
  );
};
