import React, { FC } from "react";
import { Provider } from "./components/Provider/Provider";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";

const App: FC = () => {
  return (
    <Provider>
      <RouterProvider router={router} />
    </Provider>
  );
};

export default App;
