import React, { ChangeEvent, FC, useState } from "react";
import { useAppContext } from "../Provider/AppContext";
import { Box, Grid, Input, Select } from "../Design";
import { AlmanacItem } from "./AlmanacItem";
import { ItemType, typeOptions } from "../../dtos/dtos";
import { pinStorage } from "../../util/localStorage";
import { usePersistedState } from "../../util/persistedState";

export const Almanac: FC = () => {
  const { almanac: items, lang } = useAppContext();
  const [searchTerm, setSearchTerm] = usePersistedState<string>("search", "");
  const [selectedType, setSelectedType] = usePersistedState<ItemType | "">(
    "filter",
    ""
  );
  const [pinnedItems, setPinnedItems] = useState<string[]>(pinStorage.get());

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const togglePinItem = (itemId: string) => {
    setPinnedItems((prev) => {
      let newPinnedItems: string[] = [];
      if (prev.includes(itemId)) {
        newPinnedItems = prev.filter((id) => id !== itemId);
      } else {
        newPinnedItems = [...prev, itemId];
      }
      pinStorage.set(newPinnedItems);
      return newPinnedItems;
    });
  };

  const filteredItems = items?.filter((item) => {
    const search = searchTerm.toLowerCase();
    try {
      if (selectedType && item.type !== selectedType) {
        return false;
      }

      if (!search && !selectedType) return false;

      return (
        item.eng.name?.toLowerCase().includes(search) ||
        item.pl.name?.toLowerCase().includes(search)
      );
    } catch (error) {
      console.log("bad item:", item);
      console.log(error);
      return false;
    }
  });

  const isPinned = (id: string) => pinnedItems.includes(id);

  const displayItems = items?.filter(
    (item) => isPinned(item._id) || filteredItems.includes(item)
  );

  return (
    <Box direction={"column"} gap={20} padding={[20, 5]} align="start">
      <Input
        type="search"
        placeholder="Search items..."
        value={searchTerm}
        onChange={handleSearch}
      />
      <Box align="start">
        <Select
          options={typeOptions}
          value={selectedType}
          onChange={(value) => {
            setSelectedType(value as ItemType | "");
          }}
          placeholder="Filter..."
        />
      </Box>

      <Grid gap={20} min={320}>
        {displayItems.length === 0 && <Box>Here be items</Box>}
        {displayItems.map((item) => (
          <AlmanacItem
            key={item._id}
            item={item}
            isPinned={isPinned(item._id)}
            onPinToggle={() => togglePinItem(item._id)}
            lang={lang}
          />
        ))}
      </Grid>
    </Box>
  );
};
