import React, { FC, FormEvent, useState } from "react";
import { Box, Button, Card, CardText, CardTitle, Select } from "../Design";
import { AlmanacDto, ItemDto, ItemType, typeOptions } from "../../dtos/dtos";
import { Translation } from "./Translation";
import { copyToClipboard } from "../../util/copy";
import {
  useCreateAlmanacItem,
  useDeleteAlmanacItem,
  useUpdateAlmanacItem,
} from "../../api";
import { useNavigate } from "react-router";

export const Form: FC<{ initialItem?: AlmanacDto }> = ({ initialItem }) => {
  const defaultItem: AlmanacDto = {
    _id: "",
    pl: {
      name: "",
      max: "",
      tests: "",
      description: "",
    },
    eng: {
      name: "",
      max: "",
      tests: "",
      description: "",
    },
    type: ItemType.RULE,
  };

  const navigate = useNavigate();

  const [item, setItem] = useState<AlmanacDto>(initialItem || defaultItem);

  const handleInputChange = (
    e: FormEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement> | string,
    lang: "pl" | "eng",
    field: keyof ItemDto
  ) => {
    const value = typeof e === "string" ? e : e.currentTarget.value;
    setItem((prevItem) => ({
      ...prevItem,
      [lang]: {
        ...prevItem[lang],
        [field]: value,
      },
    }));
  };

  const handleGeneralChange = (
    e: FormEvent<HTMLInputElement>,
    field: keyof AlmanacDto
  ) => {
    const value = e.currentTarget.value;
    setItem((prevItem) => ({
      ...prevItem,
      [field]: value,
    }));
  };

  const getFinal = (): AlmanacDto => {
    const finalItem = { ...item };
    return finalItem;
  };

  const [update] = useUpdateAlmanacItem(() => navigate("/"));

  const onUpdate = () => {
    update(getFinal());
  };

  const [create] = useCreateAlmanacItem(() => navigate("/"));
  const onCreate = () => {
    create(getFinal());
  };

  const [del] = useDeleteAlmanacItem(() => navigate("/"));
  const onDelete = () => {
    if (!window.confirm("Are you sure you want to delete this item?")) return;

    del(getFinal());
  };

  return (
    <div>
      <Card>
        <CardTitle>Form</CardTitle>
        <CardText>
          <Box direction="column" gap={20}>
            <Box gap={20} justify="start">
              <Translation
                language="eng"
                data={item.eng}
                handleInputChange={handleInputChange}
              />
              <Translation
                language="pl"
                data={item.pl}
                handleInputChange={handleInputChange}
              />
            </Box>
            <Box direction="column" gap={10}>
              <label>
                Type:
                <Select
                  options={typeOptions}
                  value={item.type}
                  onChange={(value) => {
                    setItem((prevItem) => ({
                      ...prevItem,
                      type: value as ItemType,
                    }));
                  }}
                  placeholder="Type"
                />
              </label>
            </Box>
            <Box gap={20}>
              <Button
                onClick={() => {
                  const item = getFinal();
                  copyToClipboard(item);
                }}
              >
                Copy
              </Button>
              {!initialItem && <Button onClick={onCreate}>Create</Button>}
              {initialItem && (
                <>
                  <Button onClick={onUpdate}>Update</Button>
                  <Box flex={10} justify="end">
                    <Button onClick={onDelete} danger>
                      Delete
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </CardText>
      </Card>
    </div>
  );
};
