import { Legend } from "../components/Weaver/legend";

export interface ItemDto {
  id: string;
  _id?: string;
  lang?: Lang;
  name: string;
  description: string;
  type: ItemType;
  max?: string;
  tests?: string;
  attribute?: string;
  skillAdvanced?: boolean;
  skillGrouped?: boolean;
}

export type Lang = "eng" | "pl";

export type ItemDtoStripped = Omit<ItemDto, "type" | "id" | "_id" | "lang">;

export interface AlmanacDto {
  _id: string;
  type: ItemType;
  pl: TranslationDto;
  eng: TranslationDto;
  attribute?: string;
  skillAdvanced?: boolean;
  skillGrouped?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface TranslationDto {
  name?: string;
  description?: string;
  max?: string;
  tests?: string;
}

export enum ItemType {
  RULE = "RULE",
  SKILL = "SKILL",
  TALENT = "TALENT",
  CONDITION = "CONDITION",
  PSYCHOLOGY = "PSYCHOLOGY",
  WEAPON_QUALITY = "WEAPON_QUALITY",
  WEAPON_FLAW = "WEAPON_FLAW",
  ARMOUR_QUALITY = "ARMOUR_QUALITY",
  ARMOUR_FLAW = "ARMOUR_FLAW",
  MONSTER_TRAIT = "MONSTER_TRAIT",
}

export const typeOptions = Object.values(ItemType) as ItemType[];

export const getItemTypeColor = (type: ItemType): string => {
  switch (type) {
    case ItemType.RULE:
      return "#4b75f0";
    case ItemType.MONSTER_TRAIT:
      return "#b9b9b9";
    case ItemType.PSYCHOLOGY:
      return "#357235";
    case ItemType.TALENT:
      return "#5ce4ff";
    case ItemType.SKILL:
      return "#e02a68";
    case ItemType.WEAPON_QUALITY:
    case ItemType.WEAPON_FLAW:
    case ItemType.ARMOUR_QUALITY:
    case ItemType.ARMOUR_FLAW:
      return "#3d3d3d";
    case ItemType.CONDITION:
      return "#ff9118";
  }
};

export interface KnotDto {
  _id: string;
  parentId?: string;
  type: "main" | "extra";
  title: string;
  content: string;
  color: Legend | string;
}

export interface WeaverDto {
  _id: string;
  knots: KnotDto[];
}
