import { ChangeEvent, FC, HTMLProps, PropsWithChildren } from "react";
import classNames from "classnames";
import "papercss/dist/paper.min.css";
import styled from "styled-components";

interface DefaultProps extends PropsWithChildren {
  className?: string;
}

const _Container: FC<
  DefaultProps & { maxWidth: number; minWidth?: number }
> = ({ children, className, maxWidth, minWidth }) => {
  return (
    <div
      className={classNames("container", className)}
      style={{
        maxWidth: maxWidth + "px",
        minWidth: minWidth ? minWidth + "px" : undefined,
      }}
    >
      {children}
    </div>
  );
};

export const Container = styled(_Container)`
  @media (max-width: 768px) {
    width: 95%;
  }
`;

const _Box: FC<DefaultProps & { border?: boolean }> = ({
  children,
  className,
  border,
}) => {
  const borderClass = border ? "border" : "";
  return <div className={classNames(borderClass, className)}>{children}</div>;
};
export const Box = styled(_Box)<{
  direction?: "row" | "column";
  align?:
    | "center"
    | "start"
    | "end"
    | "stretch"
    | "baseline"
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly";
  justify?:
    | "center"
    | "start"
    | "end"
    | "space-between"
    | "space-around"
    | "space-evenly";
  gap?: number;
  flex?: number;
  padding?: number | number[];
  style?: any;
}>`
  box-sizing: border-box;
  display: flex;
  flex: ${(props) => props.flex || 1};
  flex-direction: ${(props) => props.direction || "row"};
  align-items: ${(props) => props.align || "stretch"};
  justify-content: ${(props) => props.justify || "stretch"};
  gap: ${(props) => props.gap || 0}px;
  padding: ${(props) =>
    Array.isArray(props.padding)
      ? props.padding.map((p) => `${p}px`).join(" ")
      : `${props.padding}px`};
`;

export const Grid = styled.div<{ min: number; gap?: number }>`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${(props) => props.min}px, 1fr)
  );
  gap: ${(props) => props.gap || 0}px;
`;

export const Input: FC<DefaultProps & HTMLProps<HTMLInputElement>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <input
      className={classNames("input", className)}
      {...props}
      style={{ width: "100%" }}
    />
  );
};

export const Textarea: FC<DefaultProps & HTMLProps<HTMLTextAreaElement>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <textarea
      className={classNames(className)}
      {...props}
      style={{ width: "100%" }}
    />
  );
};

interface SelectProps {
  options: string[];
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
}

export const Select: FC<SelectProps> = ({
  options,
  value,
  onChange,
  placeholder = "Select...",
}) => {
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onChange(event.target.value);
  };

  return (
    <select value={value} onChange={handleChange}>
      <option value="">{placeholder}</option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export const Card: FC<DefaultProps & { onClick?: () => void }> = ({
  children,
  className,
  onClick,
}) => {
  return (
    <div className={classNames("card", className)} onClick={onClick}>
      <div className="card-body">{children}</div>
    </div>
  );
};

export const CardTitle: FC<DefaultProps> = ({ children, className }) => {
  return <h4 className={classNames("card-title", className)}>{children}</h4>;
};

export const CardSubtitle: FC<DefaultProps> = ({ children, className }) => {
  return (
    <div className={classNames("card-subtitle", className)}>{children}</div>
  );
};

const _CardText: FC<DefaultProps> = ({ children, className }) => {
  return <p className={classNames("card-text", className)}>{children}</p>;
};
export const CardText = styled(_CardText)`
  white-space: pre-wrap;
  line-height: 1.5;
`;

interface ButtonProps extends DefaultProps {
  onClick?: () => void;
  disabled?: boolean;
  active?: boolean;
  danger?: boolean;
  padding?: number | number[];
  background?: string;
}

export const Button: FC<ButtonProps> = ({
  children,
  className,
  onClick,
  disabled,
  active,
  danger,
  padding,
  background,
}) => {
  return (
    <button
      type="button"
      className={classNames(
        className,
        active && "btn-success",
        danger && "btn-danger"
      )}
      onClick={onClick}
      disabled={disabled}
      style={{
        padding: Array.isArray(padding)
          ? padding.map((p) => `${p}px`).join(" ")
          : `${padding}px`,
        background: background,
      }}
    >
      {children}
    </button>
  );
};

export const Content = styled.div`
  p {
    margin-bottom: 10px !important;
  }

  b,
  strong {
    color: brown;
  }
`;
