import { createBrowserRouter, Link, useParams } from "react-router-dom";
import { Almanac } from "./components/Almanac/Almanac";
import { useAppContext } from "./components/Provider/AppContext";
import { ItemType } from "./dtos/dtos";
import { Pairer } from "./components/Pairer";
import { Form } from "./components/Form";
import { useAlmanacItem } from "./util/almanac";
import React from "react";
import { Weaver } from "./components/Weaver/Weaver";
import { View } from "./components/Design/View";
import { useGetWeaversList } from "./api";
import { Card } from "./components/Design";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <View>
        <Almanac />
      </View>
    ),
  },
  {
    path: "/pairer",
    Component: () => {
      const { itemsSeparate } = useAppContext();
      const toPair = itemsSeparate.filter(
        (item) => item.type === ItemType.SKILL
      );
      return (
        <View>
          <Pairer items={toPair} />
        </View>
      );
    },
  },
  {
    path: "/form",
    element: (
      <View>
        <Form />
      </View>
    ),
  },
  {
    path: "/form/:id",
    Component: () => {
      const { id } = useParams<{ id: string }>();
      const item = useAlmanacItem(id as string, { allowCache: false });
      if (!item) {
        return null;
      }
      return (
        <View>
          <Form initialItem={item} />;
        </View>
      );
    },
  },
  {
    path: "/weaver",
    Component: () => {
      const [weavers = []] = useGetWeaversList();
      return (
        <View full config={false}>
          {weavers.map((weaver) => (
            <Link to={`/weaver/${weaver._id}`} key={weaver._id}>
              <Card>{weaver._id}</Card>
            </Link>
          ))}
        </View>
      );
    },
  },
  {
    path: "/weaver/:id",
    Component: () => {
      const { id } = useParams<{ id: string }>();
      if (!id) {
        return null;
      }
      return (
        <View full config={false}>
          <Weaver id={id} />
        </View>
      );
    },
  },
]);
