import { useQuery } from "@tanstack/react-query";
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from "react";
import { ItemDto, AlmanacDto, Lang } from "../../dtos/dtos";
import { getItemsSeparate, useGetAlmanac } from "../../api";

const context = createContext({
  lang: "eng" as Lang,
  setLang: (lang: Lang) => {},
  almanac: [] as AlmanacDto[],
  itemsSeparate: [] as ItemDto[],
});

export const useAppContext = () => {
  return useContext(context);
};

const { Provider } = context;

export const AppContext: FC<PropsWithChildren> = ({ children }) => {
  const [lang, setLang] = useState<Lang>("eng");

  const [almanac] = useGetAlmanac();

  const { data: itemsSeparateData } = useQuery({
    queryFn: getItemsSeparate,
    queryKey: ["itemsSeparate"],
    enabled: false, // disabled by default
  });
  const itemsSeparate = itemsSeparateData?.items || [];

  return (
    <Provider
      value={{
        lang,
        setLang,
        itemsSeparate,
        almanac,
      }}
    >
      {children}
    </Provider>
  );
};
