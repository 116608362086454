import { useState } from "react";

export const usePersistedState = <T>(key: string, defaultValue: T) => {
  const [state, setState] = useState<T>(() => {
    const persistedValue = localStorage.getItem(key);
    if (persistedValue) {
      try {
        return JSON.parse(persistedValue);
      } catch {
        return persistedValue;
      }
    }
    return defaultValue;
  });
  const setLocalStoredState = (value: T) => {
    const valueToStore = value instanceof Function ? value(state) : value;
    localStorage.setItem(key, JSON.stringify(valueToStore));
    setState(valueToStore);
  };
  return [state, setLocalStoredState] as const;
};
