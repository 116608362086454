import React, { PropsWithChildren } from "react";
import {
  Box,
  Button,
  Card,
  CardSubtitle,
  CardText,
  CardTitle,
  Content,
} from "../Design";
import { AlmanacDto, getItemTypeColor, ItemType } from "../../dtos/dtos";
import styled from "styled-components";
import { getTranslation } from "../../util/almanac";

const AlmanacCard = styled(Card)<{ big: boolean }>`
  ${({ big }) => big && "grid-column: span 2;"}
`;

interface AlmanacItemProps {
  item: AlmanacDto;
  lang: "pl" | "eng";
  isPinned?: boolean;
  onPinToggle?: () => void;
  children?: React.ReactNode;
}

export const AlmanacItem = ({
  item,
  isPinned,
  onPinToggle,
  children,
  lang,
}: AlmanacItemProps) => {
  const skillTags = () => {
    const res: string[] = [];
    if (item.attribute) {
      res.push(`(${item.attribute})`);
    }

    res.push(item.skillAdvanced ? "Advanced" : "Basic");
    if (item.skillGrouped) {
      res.push("Grouped");
    }
    return res.join(", ");
  };

  const translation = getTranslation(item, lang);
  const longDescription = (translation.description?.length || 0) > 1200;

  return (
    <AlmanacCard big={longDescription}>
      <Box gap={20} justify="space-between">
        <Box flex={10}>
          <CardTitle>{translation.name}</CardTitle>
        </Box>

        <Box gap={10} align="flex-end" justify="start">
          <Button active={isPinned} onClick={onPinToggle}>
            Pin
          </Button>
          <a href={`/form/${item._id}`}>
            <Button>Edit</Button>
          </a>
        </Box>
      </Box>
      <TypeLabel type={item.type}>{item.type}</TypeLabel>
      {item.type === ItemType.SKILL && (
        <CardSubtitle>{skillTags()}</CardSubtitle>
      )}
      {translation.max && (
        <CardSubtitle>
          <b>Max:</b> {translation.max}
        </CardSubtitle>
      )}
      {translation.tests && (
        <CardSubtitle>
          <b>Tests:</b> {translation.tests}
        </CardSubtitle>
      )}

      <CardText>
        <Content
          dangerouslySetInnerHTML={{ __html: translation.description || "" }}
        />
      </CardText>
      {children}
    </AlmanacCard>
  );
};

export const AlmanacItemShort = ({ children }: PropsWithChildren) => {
  return <div>FIX IT!</div>;
  // return (
  //   <Card>
  //     <CardTitle>{item.name}</CardTitle>
  //     <CardText>
  //       <div
  //         dangerouslySetInnerHTML={{
  //           __html: item.description.substring(0, 150) + "...",
  //         }}
  //       />
  //     </CardText>
  //     {children}
  //   </Card>
  // );
};

const TypeLabel = styled.div<{ type: ItemType }>`
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Courier New", Courier, monospace;
  color: ${({ type }) => getItemTypeColor(type)};
  font-size: 0.8em;
  margin-bottom: 10px;
`;
