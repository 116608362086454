import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Knot } from "./Knot";
import { useGetWeaver, useUpdateWeaver } from "../../api";
import styled from "styled-components";
import { KnotDto, WeaverDto } from "../../dtos/dtos";
import { Legend } from "./legend";
import { contextualise } from "./util";
import { debounce } from "lodash";

const Container = styled.div`
  padding: 20px;

  * {
    font-family: Helvetica, sans-serif;
    line-height: 1.8;
  }
`;

const KnotWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-bottom: 20px;
  align-items: start;
  position: relative;
`;

const MainKnotContainer = styled.div`
  grid-column: 1 / 2;
`;

const ExtraKnotGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 10px;
  grid-column: 2 / 3;
`;

const AddButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin: 5px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Weaver: React.FC<{ id: string }> = ({ id }) => {
  const [weaver, setWeaver] = useState<WeaverDto | null>(null);
  const [dbData] = useGetWeaver(id);
  const [_updateWeaver] = useUpdateWeaver();

  const updateDbData = useCallback(
    debounce((updatedWeaver: WeaverDto) => _updateWeaver(updatedWeaver), 300),
    [_updateWeaver]
  );

  useEffect(() => {
    if (dbData && !weaver) {
      setWeaver(dbData);
    }
  }, [dbData]);

  const handleAddMainKnot = (position: number) => {
    if (!weaver) return;

    const newKnot: KnotDto = {
      _id: uuidv4(),
      type: "main",
      title: "",
      content: "",
      color: Legend.SOLID,
      parentId: undefined,
    };

    const newKnots = [...weaver.knots];
    newKnots.splice(position, 0, newKnot);

    const updatedWeaver = { ...weaver, knots: newKnots };
    setWeaver(updatedWeaver);
    updateDbData(updatedWeaver);
  };

  const handleAddExtraKnot = (mainKnotId: string) => {
    if (!weaver) return;

    const newKnot: KnotDto = {
      _id: uuidv4(),
      type: "extra",
      title: "",
      content: "",
      color: Legend.GAMING,
      parentId: mainKnotId,
    };

    const updatedKnots = [...weaver.knots, newKnot];
    const updatedWeaver = { ...weaver, knots: updatedKnots };
    setWeaver(updatedWeaver);
    updateDbData(updatedWeaver);
  };

  const handleEditKnot = (id: string, field: keyof KnotDto, value: string) => {
    if (!weaver) return;

    const updatedKnots = weaver.knots.map((knot) =>
      knot._id === id ? { ...knot, [field]: value } : knot
    );
    const updatedWeaver = { ...weaver, knots: updatedKnots };
    setWeaver(updatedWeaver);
    updateDbData(updatedWeaver);
  };

  const handleRemoveKnot = (id: string) => {
    if (!weaver) return;

    const removeKnot = (knots: KnotDto[], idToRemove: string): KnotDto[] => {
      return knots
        .filter((knot) => knot._id !== idToRemove)
        .map((knot) => ({
          ...knot,
        }));
    };

    const updatedKnots = removeKnot(weaver.knots, id);
    const updatedWeaver = { ...weaver, knots: updatedKnots };
    setWeaver(updatedWeaver);
    updateDbData(updatedWeaver);
  };

  const getExtraKnots = (parentId: string) =>
    weaver?.knots.filter((knot) => knot.parentId === parentId) || [];

  const handleContextualise = (knot: KnotDto) => {
    return contextualise(knot, weaver?.knots || []);
  };

  if (!weaver) {
    return <div>Loading...</div>;
  }

  const isKnotCurrent = (knot: KnotDto) => {
    return (
      knot.content === dbData?.knots.find((k) => k._id === knot._id)?.content
    );
  };

  return (
    <Container>
      <AddButton onClick={() => handleAddMainKnot(0)}>+</AddButton>
      <div>
        {weaver.knots
          .filter((knot) => !knot.parentId)
          .map((knot, index) => (
            <React.Fragment key={knot._id}>
              <KnotWrapper>
                <MainKnotContainer>
                  <Knot
                    knot={knot}
                    onEdit={handleEditKnot}
                    onAddExtra={handleAddExtraKnot}
                    onRemove={handleRemoveKnot}
                    onContextualise={handleContextualise}
                    current={isKnotCurrent(knot)}
                  />
                </MainKnotContainer>
                <ExtraKnotGrid>
                  {getExtraKnots(knot._id).map((extraKnot) => (
                    <div key={extraKnot._id}>
                      <Knot
                        knot={extraKnot}
                        onEdit={handleEditKnot}
                        onAddExtra={handleAddExtraKnot}
                        onRemove={handleRemoveKnot}
                        onContextualise={handleContextualise}
                        current={isKnotCurrent(extraKnot)}
                      />
                    </div>
                  ))}
                </ExtraKnotGrid>
              </KnotWrapper>
              <AddButton onClick={() => handleAddMainKnot(index + 1)}>
                +
              </AddButton>
            </React.Fragment>
          ))}
      </div>
    </Container>
  );
};
