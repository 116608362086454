import styled from "styled-components";
import { Box } from "../Design";
import { useAppContext } from "../Provider/AppContext";

const ConfigBox = styled(Box)`
  position: fixed;
  left: 10px;
  bottom: 10px;
  button {
    padding: 10px 15px;
  }
`;
export const Config = () => {
  const { lang, setLang } = useAppContext();

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };

  const toggleLang = () => {
    setLang(lang === "eng" ? "pl" : "eng");
  };

  return (
    <ConfigBox gap={10}>
      <button onClick={scrollTop}>↑</button>
      <button onClick={toggleLang}>❂</button>
    </ConfigBox>
  );
};
