export function copyToClipboard(obj: any): void {
  const jsonStr = typeof obj !== "string" ? JSON.stringify(obj) : obj;

  let copied = jsonStr;

  try {
    copied = JSON.parse(jsonStr);
  } catch {}
  (window as any).copied = copied;
  console.log("window.copied:");
  console.log(copied);

  // Create a temporary textarea element to hold the JSON string
  const tempTextArea = document.createElement("textarea");
  tempTextArea.value = jsonStr;
  document.body.appendChild(tempTextArea);

  tempTextArea.select();
  tempTextArea.setSelectionRange(0, 99999); // For mobile devices

  document.execCommand("copy");

  document.body.removeChild(tempTextArea);
}
