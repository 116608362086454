import { QueryClient } from "./QueryClient";
import { FC, PropsWithChildren } from "react";
import { AppContext } from "./AppContext";

export const Provider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <QueryClient>
      <AppContext>{children}</AppContext>
    </QueryClient>
  );
};
