import React, { useEffect, useState } from "react";
import { ItemDto, ItemDtoStripped, AlmanacDto } from "../../dtos/dtos";
import { AlmanacItemShort } from "../Almanac/AlmanacItem";
import { Box, Card, CardTitle, Grid, Select } from "../Design";
import { copyToClipboard } from "../../util/copy";

interface Props {
  items: ItemDto[];
}

export const Pairer: React.FC<Props> = ({ items }) => {
  const [pairs, setPairs] = useState<Omit<AlmanacDto, "_id">[]>([]);
  const [remainingItemsEng, setRemainingItemsEng] = useState<ItemDto[]>([]);
  const [remainingItemsPL, setRemainingItemsPL] = useState<ItemDto[]>([]);

  useEffect(() => {
    setRemainingItemsPL(items.filter((item) => item.lang === "pl"));
    setRemainingItemsEng(items.filter((item) => item.lang === "eng"));
  }, [items]);

  const stripItem = (item: ItemDto): ItemDtoStripped => {
    const { id, type, lang, ...rest } = item;
    return rest;
  };

  const handlePair = (pl: ItemDto, eng: ItemDto) => {
    const newPair = {
      pl: stripItem(pl),
      eng: stripItem(eng),
      name: eng.name,
      type: eng.type,
    };

    setPairs([newPair, ...pairs]);
    setRemainingItemsPL(remainingItemsPL.filter((data) => data !== pl));
    setRemainingItemsEng(remainingItemsEng.filter((data) => data !== eng));
  };

  const handleUnpair = (pair: Omit<AlmanacDto, "id">) => {
    const originalPL = items.find(
      (item) => item.name === pair.pl.name && item.lang === "pl"
    );
    const originalEng = items.find(
      (item) => item.name === pair.eng.name && item.lang === "eng"
    );

    if (originalPL && originalEng) {
      setRemainingItemsPL([originalPL, ...remainingItemsPL]);
      setRemainingItemsEng([originalEng, ...remainingItemsEng]);
    }

    setPairs(pairs.filter((p) => p !== pair));
  };

  const copyAIAssistantPrompt = () => {
    copyToClipboard(`
group Polish:
${remainingItemsPL.map(
  (item) => `
${item.name} - ${item.description.substring(0, 50)}`
)}

group English:
${remainingItemsEng.map(
  (item) => `
${item.name} - ${item.description.substring(0, 50)}`
)}

There are two groups of the same items.
For each item of Polish group, find its equivalent in English group.   
    `);
  };

  const copyPairsToClipboard = () => {
    copyToClipboard(JSON.stringify(pairs, null, 4));
  };

  // @ts-ignore
  // @ts-ignore
  return (
    <div>
      <h3>Pairing Component</h3>
      <Box direction="column" align="start">
        <Box gap={10}>
          <h4>Remaining Items</h4>
          <button type="button" onClick={copyAIAssistantPrompt}>
            Copy to clipboard
          </button>
        </Box>
        <Box gap={40}>
          <Box direction="column" flex={1} gap={10}>
            {remainingItemsPL.map((pl) => (
              <AlmanacItemShort key={pl.id}>
                <Select
                  options={remainingItemsEng.map((eng) => eng.name)}
                  value=""
                  onChange={(value) => {
                    const selectedItem = remainingItemsEng.find(
                      (eng) => eng.name === value
                    );
                    if (selectedItem) {
                      handlePair(pl, selectedItem);
                    }
                  }}
                  placeholder="Select Pair"
                />
              </AlmanacItemShort>
            ))}
          </Box>
          <Box direction="column" flex={1} gap={20}>
            {remainingItemsEng.map((eng) => (
              <AlmanacItemShort key={eng.id} />
            ))}
          </Box>
        </Box>
      </Box>
      <Box direction="column" align="start">
        <Box gap={10}>
          <h4>Pairs</h4>
          <button type="button" onClick={copyPairsToClipboard}>
            Copy to clipboard
          </button>
        </Box>
        <Grid min={280}>
          {pairs.map((pair, index) => (
            <Card
              key={index}
              // onClick={() => handleUnpair(pair)}
            >
              <CardTitle>
                {pair.eng.name} = {pair.pl.name}
              </CardTitle>
            </Card>
          ))}
        </Grid>
      </Box>
    </div>
  );
};
