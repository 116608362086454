import React from "react";
import styled from "styled-components";
import { getLegendLabel, legendOptions } from "./legend";

interface KnotColorInputProps {
  color: string;
  onChange: (value: string) => void;
}

const Select = styled.select`
  margin-bottom: 0px;
  font-size: 12px;
  padding: 5px;
  width: 80px;
  border: none;
  align-self: flex-end;
  opacity: 0.6;
`;

export const KnotColorInput: React.FC<KnotColorInputProps> = ({
  color,
  onChange,
}) => {
  return (
    <Select value={color} onChange={(e) => onChange(e.target.value)}>
      {legendOptions.map((option) => {
        const label = getLegendLabel(option);
        return (
          <option key={option} value={option}>
            {label}
          </option>
        );
      })}
    </Select>
  );
};
