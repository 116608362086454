import { AlmanacDto, KnotDto } from "../dtos/dtos";

enum LocalStorageKeys {
  ALMANAC = "almanac",
  PINNED = "pinned",
  FILTER = "filter",
  KNOT = "knot",
}

type Cached<T> = T & { cached?: boolean };

export const isCached = <T>(item: T): item is Cached<T> =>
  (item as Cached<T>).cached !== undefined;

export const asCached = <T>(item: T): Cached<T> => ({
  ...item,
  cached: true,
});

const createArrayStorage = <T>(key: LocalStorageKeys, log?: boolean) => ({
  get: (): Cached<T> => {
    const items = localStorage.getItem(key);

    const getResult = items ? JSON.parse(items) : [];
    if (log) {
      console.log("get", key, getResult);
    }
    return getResult;
  },
  set: (items: T) => {
    if (log) {
      console.log("set", key, items);
    }
    localStorage.setItem(key, JSON.stringify(items));
  },
});

export const almanacStorage = createArrayStorage<AlmanacDto[]>(
  LocalStorageKeys.ALMANAC
);

export const pinStorage = createArrayStorage<string[]>(LocalStorageKeys.PINNED);

export const knotStorage = createArrayStorage<KnotDto[]>(
  LocalStorageKeys.KNOT,
  true
);
