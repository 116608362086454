import { FC, ReactNode } from "react";
import { Config } from "../Config";
import { Nav } from "../Nav";
import { Container } from "./index";

interface ViewProps {
  children: ReactNode;
  config?: boolean;
  full?: boolean;
}
export const View: FC<ViewProps> = ({ children, config = true, full }) => {
  return (
    <Container maxWidth={full ? 5000 : 1300} minWidth={full ? 2000 : undefined}>
      <Nav />
      {children}
      {config && <Config />}
    </Container>
  );
};
