import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { KnotColorInput } from "./KnotColorInput";
import { getLegendColor, Legend } from "./legend";
import { Box, Button } from "../Design";
import { KnotDto } from "../../dtos/dtos";
import { usePonder } from "./ponder";

interface KnotProps {
  knot: KnotDto;
  onEdit: (id: string, field: keyof KnotDto, value: string) => void;
  onAddExtra: (mainKnotId: string) => void;
  onRemove: (id: string) => void;
  onContextualise: (knot: KnotDto) => string;
  current: boolean;
}

const KnotContainer = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  padding: 10px;
  margin: 10px 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const AddButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin: 5px;
  position: absolute;
  top: 10px;
  right: 40px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RemoveButton = styled.button`
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin: 5px;
  position: absolute;
  top: 10px;
  right: 5px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Textarea = styled.textarea`
  margin-bottom: 10px;
  border: 0;
  overflow: hidden;
  resize: none;
  min-height: 50px;
`;

const SaveIndicator = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  color: red;
  display: flex;
  align-items: center;
  font-size: 18px;
`;

const withTitle = false;

export const Knot: React.FC<KnotProps> = ({
  knot,
  onEdit,
  onAddExtra,
  onRemove,
  onContextualise,
  current,
}) => {
  const isMainKnot = knot.type === "main";
  const isExtraKnot = knot.type === "extra";
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${
        textareaRef.current.scrollHeight + 60
      }px`;
    }
  }, [knot.content]);

  const handleContentChange = (value: string) => {
    onEdit(knot._id, "content", value);
  };

  const onPonder = usePonder(knot, onContextualise, (value: string) => {
    handleContentChange(value);
  });

  return (
    <KnotContainer color={getLegendColor(knot.color as Legend)}>
      {withTitle && (
        <input
          type="text"
          value={knot.title}
          onChange={(e) => onEdit(knot._id, "title", e.target.value)}
          placeholder="Title"
          style={{ marginBottom: "10px" }}
        />
      )}
      <Textarea
        ref={textareaRef}
        value={knot.content}
        onChange={(e) => handleContentChange(e.target.value)}
        placeholder=""
      />
      {!current && <SaveIndicator title="Content not saved">⚠️</SaveIndicator>}
      <Box gap={10} padding={[10, 0]} justify="end">
        <Button padding={5} onClick={onPonder} background="none">
          Ponder
        </Button>
        {isExtraKnot && (
          <KnotColorInput
            color={knot.color}
            onChange={(value) => onEdit(knot._id, "color", value)}
          />
        )}
      </Box>
      {isMainKnot && (
        <>
          <AddButton
            onClick={(e) => {
              e.stopPropagation();
              onAddExtra(knot._id);
            }}
          >
            +
          </AddButton>
          <RemoveButton
            onClick={(e) => {
              e.stopPropagation();
              if (knot.title || knot.content) {
                if (
                  window.confirm("Are you sure you want to remove this knot?")
                ) {
                  onRemove(knot._id);
                }
              } else {
                onRemove(knot._id);
              }
            }}
          >
            -
          </RemoveButton>
        </>
      )}
      {isExtraKnot && (
        <RemoveButton
          onClick={(e) => {
            e.stopPropagation();
            if (knot.title || knot.content) {
              if (
                window.confirm("Are you sure you want to remove this knot?")
              ) {
                onRemove(knot._id);
              }
            } else {
              onRemove(knot._id);
            }
          }}
        >
          -
        </RemoveButton>
      )}
    </KnotContainer>
  );
};
