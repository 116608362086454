import { Legend } from "./legend";
import { KnotDto } from "../../dtos/dtos";
import { useAiStream } from "../../api/api-util";

export const getLegendPropmt = (legend: Legend) => {
  switch (legend) {
    case Legend.SOLID:
      return "Konkretne wydarzenia w kampanii, które nadejdą";
    case Legend.GAMING:
      return "Subquest / miniwyzwanie / spotkanie / test / przeciwnik. Kwestie związane z mechaniką gry. Użyj równoważników zdań";
    case Legend.WORLD:
      return "Element świata/historii, np. nowa postać, miejsce, tajemnica. Równoważniki zdań.";
    case Legend.COMMENT:
      return "Uwaga, przemyślenie, pytanie 'metagrowe'. Na co zwrócić uwagę? Co może fajnie sprawdzić się w RPG?";
    case Legend.IDEA:
      return "Pomysł, który nie pasuje do innych kategorii. Może być związany z mechaniką, fabułą, postaciami, itd.";
    default:
      return "";
  }
};

export const usePonder = (
  knot: KnotDto,
  onContextualise: (k: KnotDto) => string,
  onEdit: (value: string) => void
) => {
  const runPonder = useAiStream(knot.content, (value) => onEdit(value));

  const onPonder = () => {
    if (
      knot.content &&
      !window.confirm("Are you sure you want to ponder this unempty knot?")
    ) {
      return;
    }
    const context = onContextualise(knot);
    const prompt = `
Jesteś narzędziem do planowania kampanii Warhammer Fantasy Roleplay 4 i asystentem MG.
Pomagasz dodać ciekawe, niebanalne pomysły, które mieszczą się w założeniach.
Wypowiadsz się bardzo lakonicznie, nie rzucasz banałami.

Oto, co mamy dotychczas.
    
${context}

====================

${getLegendPropmt(knot.color as Legend)}

Stwórz jeden, niedługi akapit. Bardzo zwięźle, bez owijania w bawełnę. Niech twórczo bazuje na tym, co już mamy.
    `;

    runPonder(prompt);
  };

  return onPonder;
};
