import { isCached } from "./localStorage";
import { useAppContext } from "../components/Provider/AppContext";
import { AlmanacDto, Lang, TranslationDto } from "../dtos/dtos";

export const useAlmanacItem = (
  id: string,
  { allowCache: boolean } = { allowCache: true }
) => {
  const { almanac } = useAppContext();
  const item = almanac.find((item) => item._id === id);
  if (isCached(item)) {
    return null;
  }
  console.log("almanac item", item);
  return item;
};

export const getTranslation = (
  item: AlmanacDto,
  lang: Lang
): TranslationDto => {
  const oppositeLang = lang === "eng" ? "pl" : "eng";
  const trans = item[lang];
  const fallbackTrans = item[oppositeLang];

  return {
    name: trans.name || fallbackTrans.name || "NO_NAME",
    description: trans.description || fallbackTrans.description,
    max: trans.max || fallbackTrans.max,
    tests: trans.tests || fallbackTrans.tests,
  };
};
