import { getLegendLabel } from "./legend";
import { KnotDto } from "../../dtos/dtos";

export const gather = (knot: KnotDto, allKnots: KnotDto[]) => {
  let gathered = [knot];
  if (knot.parentId) {
    const parent = getParent(knot, allKnots);
    if (parent) {
      gathered.unshift(parent);
      const predecessors = getPredecessors(parent, allKnots);
      gathered.unshift(...predecessors);
    }
  } else {
    const preceedingKnots = getPredecessors(knot, allKnots);
    gathered.unshift(...preceedingKnots);
  }
  return gathered;
};

const getParent = (knot: KnotDto, allKnots: KnotDto[]) => {
  return allKnots.find((k) => k._id === knot.parentId);
};

const getChildren = (knot: KnotDto, allKnots: KnotDto[]) => {
  return allKnots.filter((k) => k.parentId === knot._id);
};

const getSiblings = (knot: KnotDto, allKnots: KnotDto[]) => {
  return allKnots.filter((k) => k.parentId === knot.parentId);
};

const getPredecessors = (knot: KnotDto, allKnots: KnotDto[]) => {
  const gathered: KnotDto[] = [];

  for (const k of allKnots) {
    if (k._id === knot._id) {
      break;
    }
    gathered.push(k);
  }
  return gathered;
};

const renderGroup = (group: KnotDto[]) =>
  group.reduce((text, item) => {
    return `${text}
[${getLegendLabel(item.color)}]
${item.content}     
      `;
  }, "");

export const contextualiseOLD = (knot: KnotDto, allKnots: KnotDto[]) => {
  const gathered = gather(knot, allKnots);
  return renderGroup(gathered);
};

export const getKnotContext = (knot: KnotDto, allKnots: KnotDto[]) => {
  const parent = getParent(knot, allKnots);

  const predecessors = parent
    ? [...getPredecessors(parent, allKnots), parent]
    : getPredecessors(knot, allKnots);
  const siblings = getSiblings(knot, allKnots);
  return {
    current: knot,
    predecessors,
    siblings,
  };
};

export const contextualise = (knot: KnotDto, allKnots: KnotDto[]) => {
  const groups = getKnotContext(knot, allKnots);
  let res = `
    Najważniejsze poprzednie elementy
    ${renderGroup(groups.predecessors)}
  `;

  if (groups.siblings.length) {
    res += `
    =========
    Elementy rodzeństwa:
    ${renderGroup(groups.siblings)}
    `;
  }

  res += `
    =========
    Element do rozwinięcia:
    ${renderGroup([groups.current])}
  
  `;

  console.log(res);

  return res;
};
