import React, { FC, FormEvent } from "react";
import { Box, CardSubtitle, Input } from "../Design";
import { ItemDto, TranslationDto } from "../../dtos/dtos";
import { Quill } from "./Quill";

interface LanguageBlockProps {
  language: "pl" | "eng";
  data: TranslationDto;
  handleInputChange: (
    e: FormEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement> | string,
    lang: "pl" | "eng",
    field: keyof ItemDto
  ) => void;
}

export const Translation: FC<LanguageBlockProps> = ({
  language,
  data,
  handleInputChange,
}) => {
  const labels = {
    pl: "Polish",
    eng: "English",
  };

  return (
    <Box direction="column" gap={10}>
      <CardSubtitle>{labels[language]}</CardSubtitle>
      <label>
        Name:
        <Input
          value={data.name}
          onChange={(e) => handleInputChange(e, language, "name")}
        />
      </label>
      <label>
        Max:
        <Input
          value={data.max}
          onChange={(e) => handleInputChange(e, language, "max")}
        />
      </label>
      <label>
        Tests:
        <Input
          value={data.tests}
          onChange={(e) => handleInputChange(e, language, "tests")}
        />
      </label>
      <label>
        Description:
        <Quill
          value={data.description || ""}
          onChange={(content) =>
            handleInputChange(content, language, "description")
          }
        />
      </label>
    </Box>
  );
};
