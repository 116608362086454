export enum Legend {
  SOLID = "solid",
  GAMING = "gaming",
  WORLD = "world",
  COMMENT = "comment",
  IDEA = "idea",
}

export const legendOptions = Object.values(Legend) as Legend[];

export const getLegendColor = (legend: Legend) => {
  switch (legend) {
    case Legend.SOLID:
      return "#FFF9C4"; // Pastel Yellow
    case Legend.GAMING:
      return "#FFE0B2"; // Pastel Orange
    case Legend.WORLD:
      return "#E1BEE7"; // Pastel Purple
    case Legend.COMMENT:
      return "#C8E6C9"; // Pastel Green
    case Legend.IDEA:
      return "#B3E5FC"; // Pastel Blue
    default:
      return "gray";
  }
};

export const getLegendLabel = (legend: Legend | string) => {
  switch (legend) {
    case Legend.SOLID:
      return "🟨 Konkret";
    case Legend.GAMING:
      return "🟧 Gra";
    case Legend.WORLD:
      return "🟪 Świat";
    case Legend.COMMENT:
      return "🟩 Uwaga";
    case Legend.IDEA:
      return "🟦 Pomysł";
  }
};
