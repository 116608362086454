import { aiStream, del, get, put } from "./api-util";
import { AlmanacDto, ItemDto, WeaverDto } from "../dtos/dtos";
import { almanacStorage, asCached } from "../util/localStorage";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "../components/Provider/QueryClient";

export const getItems = async () => {
  const { items } = await get<{ items: AlmanacDto[] }>("/items");
  if (items.length) {
    almanacStorage.set(items);
  }
  return items;
};

export const getItemsSeparate = async () => {
  return get<{ items: ItemDto[] }>("/items/separate");
};

export const getAlmanac = async () => {
  const { items } = await get<{ items: AlmanacDto[] }>("/almanac");
  if (items.length) {
    almanacStorage.set(items);
  }
  return items;
};

export const useGetAlmanac = () => {
  const { data } = useQuery({
    queryFn: getAlmanac,
    queryKey: ["items"],
    initialData: almanacStorage.get().map(asCached),
  });
  return [data];
};

export const createAlmanacItem = async (item: AlmanacDto) => {
  const { _id, ...rest } = item;
  return put<{ result: AlmanacDto }>("/almanac", { item: rest }).then(
    (d) => d.result
  );
};

export const useCreateAlmanacItem = (
  onCreated?: (item: AlmanacDto) => void
) => {
  const { mutate } = useMutation({
    mutationFn: createAlmanacItem,
    onSuccess: async (item: AlmanacDto) => {
      await queryClient.invalidateQueries({
        queryKey: ["items"],
      });
      onCreated?.(item);
    },
  });
  return [mutate];
};

export const updateAlmanacItem = async (item: AlmanacDto) => {
  return put<{ result: AlmanacDto }>(`/almanac/${item._id}`, item).then(
    (d) => d.result
  );
};

export const useUpdateAlmanacItem = (
  onUpdated?: (item: AlmanacDto) => void
) => {
  const { mutate } = useMutation({
    mutationFn: updateAlmanacItem,
    onSuccess: async (item: AlmanacDto) => {
      console.log(item);
      await queryClient.invalidateQueries({
        queryKey: ["items"],
      });
      return onUpdated?.(item);
    },
  });
  return [mutate];
};

export const deleteAlmanacItem = async (item: AlmanacDto) => {
  return del(`/almanac/${item._id}`);
};

export const useDeleteAlmanacItem = (onDeleted?: () => void) => {
  const { mutate } = useMutation({
    mutationFn: deleteAlmanacItem,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["items"],
      });
      return onDeleted?.();
    },
  });
  return [mutate];
};

const getWeaversList = async () => {
  const { weavers } = await get<{ weavers: WeaverDto[] }>("/weaver");
  return weavers;
};

export const useGetWeaversList = () => {
  const { data } = useQuery({
    queryFn: getWeaversList,
    queryKey: ["weavers"],
  });
  return [data];
};

const getWeaver = async (id: string) => {
  const { weaver } = await get<{ weaver: WeaverDto }>("/weaver/" + id);
  return weaver;
};

export const useGetWeaver = (id: string) => {
  const { data } = useQuery({
    queryFn: () => getWeaver(id),
    queryKey: ["weaver", id],
  });
  return [data];
};

const updateWeaver = async (weaver: WeaverDto) => {
  return put<{ result: WeaverDto }>(`/weaver/${weaver._id}`, weaver).then(
    (d) => d.result
  );
};

export const useUpdateWeaver = (onUpdated?: (weaver: WeaverDto) => void) => {
  const { mutate } = useMutation({
    mutationFn: updateWeaver,
    onSuccess: async (weaver: WeaverDto) => {
      await queryClient.invalidateQueries({
        queryKey: ["weaver", weaver._id],
      });
      return onUpdated?.(weaver);
    },
  });
  return [mutate];
};

export const ponder = aiStream;
