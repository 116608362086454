import { getToken, onResponse } from "./token";
import { useEffect, useState } from "react";

const API_URL = process.env.REACT_APP_API_URL;

export const get = async function <T>(url: string, params?: object) {
  // Construct the URL with query parameters
  const queryString = new URLSearchParams(params as any).toString();
  let fullUrl = `${API_URL}${url}`;
  if (queryString) {
    fullUrl += `?${queryString}`;
  }

  return fetch(fullUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-Auth": getToken(),
    },
  }).then(onResponse) as T;
};

export const post = async function <T>(url: string, body: object) {
  return fetch(API_URL + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Auth": getToken(),
    },
    body: JSON.stringify(body),
  }).then(onResponse) as T;
};

export const put = async function <T>(url: string, body: object) {
  return fetch(API_URL + url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "X-Auth": getToken(),
    },
    body: JSON.stringify(body),
  }).then(onResponse) as T;
};

export const del = async function (url: string) {
  return fetch(API_URL + url, {
    method: "DELETE",
    headers: {
      "X-Auth": getToken(),
    },
  }).then(onResponse);
};

interface AiStreamConfig {
  onMessage: (data: string) => void;
  onEnd?: () => void;
}

const createEventSourceListener = (
  url: string,
  { onMessage, onEnd }: AiStreamConfig
) => {
  const params = {
    "X-Auth": getToken(),
  };
  const queryString = new URLSearchParams(params).toString();
  const fullUrl = `${url}?${queryString}`;

  const eventSource = new EventSource(fullUrl);

  eventSource.onmessage = (event) => {
    onMessage(event.data);
  };

  eventSource.onerror = (error) => {
    onEnd?.();
    eventSource.close();
  };

  return eventSource;
};

export const aiStream = async (prompt: string, config: AiStreamConfig) => {
  const { id } = await post<{ id: string }>("/ai", { prompt });
  const url = `${API_URL}/public/ai/${id}`;

  return createEventSourceListener(url, config);
};

export const useAiStream = (
  initialText: string,
  onChange: (val: string) => void
) => {
  const [_text, _setText] = useState<string>("");
  const [running, setRunning] = useState(false);

  useEffect(() => {
    if (!running) {
      _setText(initialText);
    }
  }, [initialText]);

  useEffect(() => {
    if (running) {
      onChange(_text);
    }
  }, [_text]);

  const run = (prompt: string) => {
    setRunning(true);
    _setText((prev) => prev + "🤖 ");

    aiStream(prompt, {
      onMessage: (message) => {
        const newText = JSON.parse(message);
        console.log(newText);
        if (newText === "*END*") {
          setRunning(false);
        }
        _setText((prev) => prev + newText);
      },
      onEnd: () => {
        setRunning(false);
      },
    });
  };

  return run;
};
