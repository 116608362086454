import React, { FC } from "react";
import { Box, Card } from "../Design";
import styled from "styled-components";
import { Link } from "react-router-dom";

const NavCard = styled(Card)`
  margin: 15px 0;
  .card-body {
    padding: 5px;
  }
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const Nav: FC = () => {
  return (
    <NavCard>
      <Box gap={10} padding={[10, 0]} justify="space-evenly">
        <NavLink to="/">Almanac</NavLink>
        {/*<NavLink to="/pairer">Pairer</NavLink>*/}
        <NavLink to="/form">Form</NavLink>
        <NavLink to="/weaver">Weaver</NavLink>
      </Box>
    </NavCard>
  );
};
